$spacing-unit: 8px; // Define your theme's spacing unit here
$breakpoint-md: 960px; // Define your theme's md breakpoint here
$breakpoint-lg: 1280px; // Define your theme's lg breakpoint here
$palette-text-primary: #333; // Define your theme's text primary color here
$palette-common-black: #000; // Define your theme's common black color here

.root {
  max-width: 1720px;
  margin: 0 auto;
}

.featuresContainer {
  display: flex;
  justify-content: center;
  gap: 90px;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto $spacing-unit * 8;
  padding-left: $spacing-unit * 2;
  padding-right: $spacing-unit * 2;


  @media (max-width: $breakpoint-md) {
    margin-bottom: $spacing-unit * 9;
    flex-wrap: wrap;
    gap: 32px 3px;
  }

  @media (min-width: $breakpoint-lg) {
    margin-bottom: $spacing-unit * 10;
  }
}

.featuresItem {
  width: 162px;
  min-width: 162px;
  text-align: center;
  @media (min-width: $breakpoint-lg) {
    width: 245px;
    min-width: 245px;
  }
}

.featureIcon {
  color: $palette-text-primary;
  width: 90px;
  height: 90px;
  margin-bottom: 20px;
  @media (max-width: $breakpoint-md) {
    width: 60px;
    height: 60px;
  }
}

.featuresItemTitle {
  color: $palette-common-black;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  height: 72px;
  line-height: 24px;

  @media (max-width: $breakpoint-md) {
    font-size: 16px;
    height: inherit;
  }
}

.featuresItemDesc {
  color: $palette-common-black;

  @media (min-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.featureIconContainer {
  margin: 0;
}