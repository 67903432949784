$spacing-unit: 8px; // Define your theme's spacing unit here
$breakpoint-md: 960px; // Define your theme's md breakpoint here
$breakpoint-lg: 1280px; // Define your theme's lg breakpoint here
$palette-primary-main: #8e3fbe; // Define your theme's primary color here
$palette-common-black: #000; // Define your theme's common black color here
$palette-common-helperBlack: rgba(0, 0, 0, 0.44); // Define your theme's common helperBlack color here

.wrapper {
  max-width: 1920px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: $spacing-unit * 8;
  position: relative;

  @media (max-width: $breakpoint-md) {
    margin-bottom: 60px;
  }

  .swiperWrapper {
    &:global(.swiper) {
      overflow: visible;
    }
  }

  button.buttonArrow {
    top: 50%;
    transform: translateY(-50%);
  }

  button.prevArrowButton{
    transform: translateY(-50%) rotate(180deg);
  }
}

.customPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  width: 100%;
  position: absolute;
  top: 90%;
  z-index: 9;
  gap: 10px;

  & > * {
    width: max-content;
  }

  & > :global(.swiper-pagination-bullet) {
    background-color: #fff;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  & > :global(.swiper-pagination) {
    width: max-content;
  }

  & > :global(.swiper-pagination-bullet-active) {
    background-color: #691b33;
    width: 16px;
    height: 16px;
  }
}

button.buttonLabel {
  padding: 0 44px;
}

.root {
  position: relative;
  min-height: 500px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  @media (max-width: $breakpoint-md) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-color: #f4eae9;
    min-height: 550px;
  }
}

.container {
  max-width: 60%;

  @media (max-width: $breakpoint-md) {
    max-width: 90%;
    text-align: center;
  }
}

.image {
  width: 100%;
  object-fit: cover;

  @media (max-width: $breakpoint-md) {
    height: 257px;
  }
}

.brandName {
  color: $palette-common-black;
  height: 26.5px;
  margin-bottom: $spacing-unit * 3;

  @media (max-width: $breakpoint-md) {
    height: 64px;
    margin-bottom: $spacing-unit * 3;
  }
}

.slideContainer {
  &:focus {
    outline: none;
  }
}

.title {
  margin-top: 0;
  margin-bottom: $spacing-unit * 5;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: #333;
  max-height: 95px;
  overflow: hidden;
  @media (max-width: $breakpoint-md) {
    margin-top: 24px;
    max-height: 77px;
    font-size: 32px;
    margin-bottom: $spacing-unit * 2;
  }
}

.subTitle {
  margin-top: 0;
  margin-bottom: $spacing-unit * 4;

  .description {
    color: #333;
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;
    max-height: 58px;
    overflow: hidden;
    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      max-height: 39px;
      margin-bottom: 20px;
    }
  }
}

.catalogButton {
  text-transform: initial;

  &:focus {
    text-decoration: none;
  }

  @media (max-width: $breakpoint-md) {
    padding: 15px 44px;
    margin-bottom: 54px;
  }
}

.gridItem {
  position: relative;
  display: flex;
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
    height: 257px;
  }
}

.content {
  display: flex;
  background-color: #f4eae9;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media (max-width: 720px) {
    width: 100%;
  }
}
